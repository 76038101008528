$(document).ready(function(){
	
	var current_fs, next_fs, previous_fs; //fieldsets
	var opacity;
	var currentStep = 0;
	
	// * zu allen required Feldern hinzufügen
	// $('label.required').append('&nbsp;<strong>*</strong>&nbsp;');

	// eigene Synchrone Methode, da async bei remot Anfragen zu Problemen führt.
	// https://stackoverflow.com/questions/7247250/jquery-validation-not-waiting-for-remote-validation-to-return-true-considers-fo
	$.validator.addMethod("synchronousRemote", function (value, element, param) {
    if (this.optional(element)) {
      return "dependency-mismatch";
    }

    var previous = this.previousValue(element);
    if (!this.settings.messages[element.name]) {
      this.settings.messages[element.name] = {};
    }
    previous.originalMessage = this.settings.messages[element.name].remote;
    this.settings.messages[element.name].remote = previous.message;

    param = typeof param === "string" && { url: param } || param;

    if (previous.old === value) {
      return previous.valid;
    }

    previous.old = value;
    var validator = this;
    this.startRequest(element);
    var data = {};
    data[element.name] = value;
    var valid = "pending";
    $.ajax($.extend(true, {
			url: param,
			async: false,
			mode: "abort",
			port: "validate" + element.name,
			dataType: "json",
			data: data,
			success: function (response) {
				validator.settings.messages[element.name].remote = previous.originalMessage;
				valid = response === true || response === "true";
				if (valid) {
					var submitted = validator.formSubmitted;
					validator.prepareElement(element);
					validator.formSubmitted = submitted;
					validator.successList.push(element);
					delete validator.invalid[element.name];
					validator.showErrors();
				} else {
					var errors = {};
					var message = response || validator.defaultMessage(element, "remote");
					errors[element.name] = previous.message = $.isFunction(message) ? message(value) : message;
					validator.invalid[element.name] = true;
					validator.showErrors(errors);
				}
				previous.valid = valid;
				validator.stopRequest(element, valid);
			}
    }, param));
    return valid;
	}, "Please fix this field.");
	
	var v = $("#registerform").validate({
		rules: {
			email_address: {
				required: true,
				email: true,
				synchronousRemote: { url: "/login/verifyemail/", type: "post" }
			},
			login_id: {
				required: true,
				synchronousRemote: { url: "/login/verifylogin/", type: "post" }
			},
			password: {
				required: true,
				minlength: 8
			},
			efn: {
				synchronousRemote: { url: "/login/verifyefn/", type: "post" }
			}
		},
		messages: {
			email_address: {
				synchronousRemote: "Diese E-Mail Adresse wird bereits benutzt."
			},
			efn: {
				synchronousRemote: "Die EFN ist nicht gültig."
			},
			login_id: {
				synchronousRemote: "Dieser Benutzername wird bereits benutzt. Bitte wählen Sie einen anderen Benutzernamen."
			}
		},
		errorClass: "warning"
	});

	$(".next").on('click',function(){
		
		if (v.form()) {
			currentStep++;
			current_fs = $(this).parent();
			next_fs = $(this).parent().next();
			
			//Add Class Active
			$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
			
			//show the next fieldset
			next_fs.show();
			//hide the current fieldset with style
			current_fs.animate({opacity: 0}, {
				step: function(now) {
					// for making fielset appear animation
					opacity = 1 - now;
					
					current_fs.css({
						'display': 'none',
						'position': 'relative'
					});
					next_fs.css({'opacity': opacity});
				},
				duration: 600
			});
		}
	});
	
	$(".previous").click(function(){
		
		currentStep--;
		current_fs = $(this).parent();
		previous_fs = $(this).parent().prev();
		
		//Remove class active
		$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
		
		//show the previous fieldset
		previous_fs.show();
		
		//hide the current fieldset with style
		current_fs.animate({opacity: 0}, {
			step: function(now) {
				// for making fielset appear animation
				opacity = 1 - now;
				
				current_fs.css({
					'display': 'none',
					'position': 'relative'
				});
				previous_fs.css({'opacity': opacity});
			},
			duration: 600
		});
	
	});
	
	$( "#arbeitsstatus" ).change(function() {
		if( this.value == 'apotheker' ) {
			$('.row.bemerkung').hide();
			$('.row.nachweis').show();
			$('.row.fachbereichAll').hide();
			$('.row.fachbereichApo').show();
			$('.row.aerztekammer').hide();
			$('.row.apothekerkammer').show();
		} else if( this.value == 'sonstiges' ) {
			$('.row.bemerkung').show();
			$('.row.nachweis').hide();
			$('.row.fachbereichApo').hide();
			$('.row.fachbereichAll').hide();
			$('.row.apothekerkammer').hide();
			$('.row.aerztekammer').hide();
		} else {
			$('.row.bemerkung').hide();
			$('.row.nachweis').show();
			$('.row.fachbereichApo').hide();
			$('.row.fachbereichAll').show();
			$('.row.apothekerkammer').hide();
			$('.row.aerztekammer').show();
		}
	});
	
	$( "#arbeitsstatus" ).change();
	
});